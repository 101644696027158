import { AUSTRALIA_NEWZEALAND_PAGE, WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { AUSTRALIA_NEWZEALAND_PROGRAM } from "./_programs";
import financial_services from "../../../../assets/images/oceania/financial_services.webp";
import financial_services_banner from "../../../../assets/images/oceania/financial_services_banner.webp";
const ANZ_LOCATIONS = {
    id: 1,
    name: 'Australia & New Zealand',
    slug: 'melbourne-victoria-nma',
    items: AUSTRALIA_NEWZEALAND_PROGRAM.filter(x => ["melbourne-victoria"].indexOf(x.slug) >= 0)
}
export const FINANCIAL_SERVICES = {
    id: 'financial-services',
    card_src: financial_services,
    card_title: "page.oceania.financial_services",
    card_description: "page.oceania.financial_services_description",
    banner_src: financial_services_banner,
    banner_title: "page.oceania.financial_services",
    banner_description: "Your Finance Career Awaits",
    slug: 'financial-services',
    href: `${AUSTRALIA_NEWZEALAND_PAGE}/financial-services`,
    name: "page.oceania.financial_services",
    description: "Nissan Finanical Services is a full Finance Company providing financial solutions to our retail customers at time of car purchase and wholesale funding to our Dealerships. Your career could take you from Customer Service or Compliance to Risk or be out supporting our Sales Finance teams in our Dealerships.  Nissan Finance Sales provides a significant competitive advantage and great partnership to the Motor business and the opportunities across both for your career are their for you to maximise.",
    categories: [{
        id: 1,
        name: 'Who we are',
        slug: 'who_we_are',
        description: "At Nissan Financial Services Australia and New Zealand we provide tailored financial solutions and deliver service excellence to support customers to achieve vehicle ownership and provide dealers superior value and service. We have five key areas across the business they are Finance, Sales, Operations, Risk and Compliance",
        locations: [ANZ_LOCATIONS]
    }, {
        id: 2,
        name: 'Where you can start',
        slug: 'where_you_can',
        description: "You might join in our Customer Service Centre, within our Operations team and provide exceptional customer service, exceeding customer expectations, driving first call resolution and building brand loyalty through service.",
        locations: [ANZ_LOCATIONS]
    }, {
        id: 3,
        name: 'Specialist Areas',
        slug: 'specialist_areas',
        description: "You might bring some specialist skills into our Fleet Sales Team, building the relationship between the Fleet Client, Dealer or ourselves. <br /> <br /> You could also transfer your Finance experience in Retail Credit to support our Sales Team to ensure our Customers finance applications are managed to conclusion quickly and effectively.",
        locations: [ANZ_LOCATIONS]
    }, {
        id: 4,
        name: 'Career Pathways',
        slug: 'career_pathways',
        description: "Where ever you join in Financial Services it will open up pathways to other roles within the business OR across to the Motor business OR within our Global Financial Services Function.",
        locations: [ANZ_LOCATIONS]
    }],
   work_day_link: `https://www.livehire.com/careers/nissan/jobs`
}