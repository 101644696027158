import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "../../Components/Layout";
import AboutContainer from "../../Containers/AboutContainer";
import CareersAreaContainer from "../../Containers/CareersAreaContainer";
import ErrorContainer from "../../Containers/ErrorContainer";

import HomeContainer from "../../Containers/HomeContainer";
import MeetOurPeopleContainer from "../../Containers/MeetOurPeopleContainer";
import SiteMapContainer from "../../Containers/SitemapContainer";

import {
  HOME_PAGE,
  CAREER_PAGE,
  ABOUT_PAGE,
  SITEMAP_PAGE,
  MEET_OUR_PEOPLE_PAGE,
  AMERICAS_PAGE,
  EUROPE_PAGE,
  BASENAME_PAGE,
  ASEAN_PAGE,
  AFRICA_PAGE,
  MIDDLE_EAST_PAGE,
  NML_PAGE,
  AUSTRALIA_NEWZEALAND_PAGE,
  RECRUITMENT_SCAM_PAGE,
  CAREER_DETAILS_PAGE
} from "../../interfaces/Static/routing_paths";
import AmericasContainer from "../../Containers/AmericasContainer";
import EuropeContainer from "../../Containers/EuropeContainer";
import AseanContainer from "../../Containers/AseanContainer";
import AfricaContainer from "../../Containers/AfricaContainer";
import NmlContainer from "../../Containers/NmlContainer";
import NmlJobSearchContainer from "../../Containers/NmlJobSearchContainer";
import MiddleEastContainer from "../../Containers/MiddleeastContainer";
import RecruitmentScamContainer from "../../Containers/RecruitmentScamContainer";
import CareersAreaDetailContainer from "../../Containers/CareersAreaDetailContainer";
import OceaniaContainer from "../../Containers/OceaniaContainer";

const router = createBrowserRouter(
  [
    {
      path: HOME_PAGE,
      element: <Layout />,
      children: [
        {
          path: HOME_PAGE,
          element: <HomeContainer />,
        },
        {
          path: ABOUT_PAGE,
          element: <AboutContainer />,
        },
        {
          path: CAREER_PAGE,
          element: <CareersAreaContainer />,
        },
        {
          path: CAREER_DETAILS_PAGE,
          element: <CareersAreaDetailContainer />,
        },
        {
          path: MEET_OUR_PEOPLE_PAGE,
          element: <MeetOurPeopleContainer />,
        },
        {
          path: RECRUITMENT_SCAM_PAGE,
          element: <RecruitmentScamContainer />,
        },
        {
          path: SITEMAP_PAGE,
          element: <SiteMapContainer />,
        },
        {
          path: AMERICAS_PAGE,
          element: <AmericasContainer />,
        },
        {
          path: `${AMERICAS_PAGE}/:pageId`,
          element: <AmericasContainer />,
        },
        {
          path: EUROPE_PAGE,
          element: <EuropeContainer />,
        },
        {
          path: `${EUROPE_PAGE}/:pageId`,
          element: <EuropeContainer />,
        },
        {
          path: ASEAN_PAGE,
          element: <AseanContainer />,
        },
        {
          path: `${ASEAN_PAGE}/:pageId`,
          element: <AseanContainer />,
        },
        {
          path: `${AFRICA_PAGE}`,
          element: <AfricaContainer />,
        },
        {
          path: `${AFRICA_PAGE}/:pageId`,
          element: <AfricaContainer />,
        },
        {
          path: `${NML_PAGE}`,
          element: <NmlContainer />,
        },
        {
          path: `${NML_PAGE}/:pageId`,
          element: <NmlContainer />,
        },
        {
          path: `${NML_PAGE}/job-search/:pageId`,
          element: <NmlJobSearchContainer />,
        },
        {
          path: `${MIDDLE_EAST_PAGE}`,
          element: <MiddleEastContainer />,
        },
        {
          path: `${MIDDLE_EAST_PAGE}/:pageId`,
          element: <MiddleEastContainer />,
        },
        // {
        //   path: `${AUSTRALIA_NEWZEALAND_PAGE}`,
        //   element: <OceaniaContainer />,
        // },
        // {
        //   path: `${AUSTRALIA_NEWZEALAND_PAGE}/:pageId`,
        //   element: <OceaniaContainer />,
        // },
        { path: "*", element: <ErrorContainer /> },
        { path: "404-found", element: <ErrorContainer /> },
      ],
    },
  ],
  {
    basename: BASENAME_PAGE,
  }
);
const Routes = () => <RouterProvider router={router} />;
export default Routes;
